<script setup lang="ts">
import { CurvedHeaderVariant } from '@temperworks/types'
interface Props {
  variant: CurvedHeaderVariant
}
const props = defineProps<Props>()
</script>

<template>
  <div
    class="dashboard-header"
    :class="props.variant"
  />
</template>

<style lang="scss" scoped>
@use './CurvedHeader.scss'
</style>
